









































































































































































































import {
  SportangoTextField,
  SportangoTextArea
} from "@/components/Inputs/overrides";
import { WatchLoading } from "@/decorators/Loading";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { StripeMerchantInfo } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Header } from "@/types/Table";
import { IDiscountHeader } from "@/types/SiteSettings";
import { Watch } from "vue-property-decorator";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import { numericPositiveRule, requiredFieldRule } from "@/utils/validation";
import EditIcon from "@/assets/icons/saxcons/edit-2-linear.svg";
import DeleteIcon from "@/assets/icons/saxcons/trash-linear.svg";
import Menu from "@/assets/icons/saxcons/three-dots-vertical.svg";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
type TDiscount = {
  name: string;
  description: string;
  value: number;
  created: Date;
  createdBy: string;
};

@Component({
  name: "site-settings",
  components: {
    AppDialog,
    SportangoTextField,
    SportangoTextArea,
    EditIcon,
    DeleteIcon,
    Menu,
    LottieAnimation
  }
})
export default class SiteSettings extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  CurrentUserMixin
) {
  discountRule = numericPositiveRule;
  requiredField = requiredFieldRule;
  isFormValid = false;
  isDiscountLoading = false;
  showDialog = false;
  cardConvenienceFee = 3.5;
  achConvenienceFee = 0;
  mode: "ADD" | "EDIT" = "ADD";
  editIndex: number | null = null;
  discount = {
    created: new Date(),
    createdBy: this.currentUser?.uid || ""
  } as TDiscount;
  get headers(): Array<Header<IDiscountHeader>> {
    return [
      {
        value: "value",
        text: "Discount",
        align: "start",
        width: "130"
      },
      {
        value: "name",
        text: "name",
        width: "130"
      },
      {
        value: "description",
        text: "Description",
        width: "130"
      },
      {
        value: "actions",
        text: "Actions",
        sortable: false,
        align: "end"
      }
    ];
  }
  rangeRule(v: number): boolean | string {
    // not greater than 100 and nott less than 1
    return v <= 100 && v >= 1;
  }
  get merchantInfo(): StripeMerchantInfo | undefined {
    return this.$store.getters.merchantInfo;
  }
  showAddDialog(): void {
    this.showDialog = true;
    this.mode = "ADD";
  }
  @WatchLoading()
  async deleteDiscount(index: number): Promise<void> {
    const payload = this.merchantInfo?.discounts?.splice(index, 1);
    await this.$store.dispatch("updateMerchantInfo", payload);
  }
  async editDiscount(discount: TDiscount, index: number): Promise<void> {
    this.discount = Object.assign({}, discount);
    this.editIndex = index;
    this.mode = "EDIT";
    this.showDialog = true;
  }
  @Watch("showDialog")
  dialogMovement(v: boolean) {
    if (!v) {
      this.discount = {} as TDiscount;
    }
  }
  @Watch("merchantInfo")
  updateConvenienceFee() {
    if (this.merchantInfo?.convenienceFees) {
      if (this.merchantInfo.convenienceFees.cards) {
        this.cardConvenienceFee = Number(
          (this.merchantInfo.convenienceFees.cards * 100).toFixed(1)
        );
      }
      if (this.merchantInfo.convenienceFees.ach) {
        this.achConvenienceFee = Number(
          (this.merchantInfo.convenienceFees.ach * 100).toFixed(1)
        );
      }
    }
  }
  mounted() {
    this.updateConvenienceFee();
  }

  @WatchLoading()
  async save() {
    const payload: Partial<StripeMerchantInfo> = {
      convenienceFees: {
        cards: Number((this.cardConvenienceFee / 100).toFixed(3)),
        ach: Number((this.achConvenienceFee / 100).toFixed(3))
      }
    };
    await this.$store.dispatch("updateMerchantInfo", payload);
    this.showDialog = false;
  }
  async saveDiscount() {
    this.isDiscountLoading = true;
    if (this.mode === "ADD") {
      const payload: Partial<StripeMerchantInfo> = {
        discounts:
          this.merchantInfo && this.merchantInfo?.discounts?.length
            ? [
                ...this.merchantInfo?.discounts,
                {
                  ...this.discount
                }
              ]
            : [{ ...this.discount }]
      };
      await this.$store.dispatch("updateMerchantInfo", payload);
    } else if (this.mode === "EDIT") {
      if (
        this.merchantInfo &&
        this.merchantInfo.discounts &&
        this.editIndex !== null
      ) {
        if (this.editIndex === 0) {
          this.$set(this.merchantInfo.discounts, 0, this.discount);
          const payload: Partial<StripeMerchantInfo> = {
            discounts: this.merchantInfo.discounts
          };
          await this.$store.dispatch("updateMerchantInfo", payload);
        } else {
          this.merchantInfo.discounts.splice(this.editIndex, 1, this.discount);
          const payload: Partial<StripeMerchantInfo> = {
            discounts: this.merchantInfo.discounts
          };
          await this.$store.dispatch("updateMerchantInfo", payload);
        }
      }
    }
    this.isDiscountLoading = false;
    this.showDialog = false;
  }
}
